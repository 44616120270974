import React from 'react'

const times = ({ color, onClick }) => {
  return (
    <svg onClick={onClick} width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Times</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu" transform="translate(-275.000000, -39.000000)" fill={color}>
          <polygon id="Fill-1" points="290 39.8878812 289.112119 39 282.5 45.6121187 275.887881 39 275 39.8878812 281.612119 46.4999998 275 53.1121188 275.887881 54 282.5 47.387881 289.112119 54 290 53.1121188 283.387881 46.4999998"></polygon>
        </g>
      </g>
    </svg>
  )
}

export default times
