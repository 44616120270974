import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../components/header"
//import Transition from "../components/transition"

import "../assets/scss/main.scss"

class Layout extends React.Component {
  state = {
    isDark: true,
  }

  componentDidMount() {
    // const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
    // const handleDarkMode = e => {
    //   var darkModeOn = e.matches
    //   this.setState({ isDark: darkModeOn })
    //   if (darkModeOn) {
    //     this.setDark()
    //   } else {
    //     this.setLight()
    //   }
    // }
    // darkModeMediaQuery.addListener(handleDarkMode)
    // handleDarkMode(darkModeMediaQuery)

    if (sessionStorage.getItem("isDark") === "false") {
      this.setLight()
    } else {
      this.setDark()
    }
  }

  toggleIsDark = () => {
    const { isDark } = this.state
    if (isDark) {
      this.setLight()
    } else {
      this.setDark()
    }
    this.setState({ isDark: !isDark })
  }

  setLight = () => {
    sessionStorage.setItem("isDark", false)
    const root = document.querySelector(":root")
    root.style.setProperty("--color-background", "#ffffff")
    root.style.setProperty("--color-background-high-contrast", "#ffffff")
    root.style.setProperty("--color-text", "#151515")
    root.style.setProperty("--color-text-secondary", "#151515")
    root.style.setProperty("--image-filter", "invert(0%)")
  }

  setDark = () => {
    sessionStorage.setItem("isDark", true)
    const root = document.querySelector(":root")
    root.style.setProperty("--color-background", "#151515")
    root.style.setProperty("--color-background-high-contrast", "#000000")
    root.style.setProperty("--color-text", "#ffffff")
    root.style.setProperty("--color-text-secondary", "#d2d2d2")
    root.style.setProperty("--image-filter", "invert(100%)")
  }

  render() {
    const { children, location } = this.props
    const { isDark } = this.state

    return (
      <StaticQuery
        query={graphql`
          query {
            projects: wpMenu(name: { eq: "Projects" }) {
              menuItems {
                nodes {
                  label
                  url
                } 
              }
            }
          }
        `}
        render={data => {
          return (
            <>
              <Header
                {...data}
                location={location}
                isDark={isDark}
                toggleIsDark={this.toggleIsDark}
              />
              <main className='scroll-snap-wrapper'>
                {children}
              </main>
            </>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
