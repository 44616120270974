import React from 'react'

const sun = ({ color }) => {
  return (
    <svg width="15px" height="15px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Sun</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Sun" fill={color}>
          <path d="M14.4435,23.201 C9.506,23.201 5.4885,19.1834 5.4885,14.246 C5.4885,9.30655 9.5061,5.289 14.4435,5.289 C19.38295,5.289 23.3985,9.3066 23.3985,14.244 C23.3985,19.18345 19.3809,23.201 14.4435,23.201 Z M14.4435,6.789 C10.33215,6.789 6.9885,10.13275 6.9885,14.244 C6.9885,18.35535 10.33225,21.699 14.4435,21.699 C18.55485,21.699 21.8985,18.35525 21.8985,14.244 C21.8985,10.1346 18.55475,6.789 14.4435,6.789 Z" id="Fill-1"></path>
          <polygon id="Fill-2" points="13.75 3.81835 15.25 3.81835 15.25 -7.10542736e-15 13.75 -7.10542736e-15"></polygon>
          <polygon id="Fill-3" points="13.75 28.83385 15.25 28.83385 15.25 25.0155 13.75 25.0155"></polygon>
          <polygon id="Fill-4" points="3.8945 13.787 3.91208 15.287 0.09373 15.33192 0.07615 13.83192"></polygon>
          <polygon id="Fill-5" points="28.91 13.502 28.92758 15.002 25.10923 15.04497 25.09165 13.54497"></polygon>
          <polygon id="Fill-6" points="6.457 21.3615 7.5156 22.424 4.8105 25.11735 3.7519 24.05485"></polygon>
          <polygon id="Fill-7" points="24.1875 3.715 25.2461 4.7775 22.541 7.47085 21.4824 6.40835"></polygon>
          <polygon id="Fill-8" points="4.9845 3.5605 7.6466 6.29685 6.5724 7.34375 3.9103 4.6074"></polygon>
          <polygon id="Fill-9" points="22.4295 21.49 25.0916 24.22635 24.0174 25.27325 21.3553 22.5369"></polygon>
        </g>
      </g>
    </svg>
  )
}

export default sun
