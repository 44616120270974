import React from 'react'

const bars = ({ color, onClick }) => {
  return (
    <svg onClick={onClick} width="20px" height="10px" viewBox="0 0 20 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Bars</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Projects---Movable" transform="translate(-270.000000, -41.000000)" fill={color}>
          <path d="M270,41 L290,41 L290,42.3636364 L270,42.3636364 L270,41 Z M270,49.6363636 L290,49.6363636 L290,51 L270,51 L270,49.6363636 Z" id="Fill-1"></path>
        </g>
      </g>
    </svg>
  )
}

export default bars
