import React from 'react'


const Logo = ({ color }) => (
  <svg width="393px" viewBox="0 0 393 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="0" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, 0.582000)">
        <polyline fill={color} points="28.097 36.523 0 36.523 0 0.769 5.129 0.769 5.129 31.733 28.097 31.733 28.097 36.523"></polyline>
        <path d="M41.776,14.598 L58.163,14.598 C60.566,14.598 63.433,13.819 63.433,10.11 C63.433,7.042 61.708,5.551 58.163,5.551 L41.776,5.551 L41.776,14.598 Z M41.776,31.733 L59.718,31.733 C63.509,31.733 65.775,29.428 65.775,25.558 C65.775,21.748 63.454,19.382 59.718,19.382 L41.776,19.382 L41.776,31.733 Z M58.873,36.523 L36.668,36.523 L36.668,0.769 L57.771,0.769 C64.863,0.769 68.612,3.908 68.612,9.85 C68.612,12.55 67.597,14.799 65.743,16.251 C69.285,18.444 71.081,21.597 71.081,25.626 C71.081,32.347 66.405,36.523 58.873,36.523 L58.873,36.523 Z" id="Fill-4" fill={color}></path>
        <path d="M84.722,31.738 L94.998,31.738 C103.345,31.738 108.53,26.736 108.53,18.678 C108.53,10.706 103.218,5.553 94.998,5.553 L84.722,5.553 L84.722,31.738 Z M94.998,36.523 L79.611,36.523 L79.611,0.769 L94.998,0.769 C106.228,0.769 113.77,7.991 113.77,18.744 C113.77,29.544 106.402,36.523 94.998,36.523 L94.998,36.523 Z" id="Fill-5" fill={color}></path>
        <path d="M154.43,37.418 C147.397,37.418 141.33,35.372 135.88,31.171 L135.6,30.954 L138.569,26.567 L138.89,26.81 C143.735,30.567 149.233,32.633 154.364,32.633 C160.587,32.633 164.934,29.778 164.934,25.685 C164.934,22.432 161.996,20.246 157.074,19.835 C156.163,19.767 155.275,19.706 154.42,19.647 C149.54,19.313 145.685,19.051 142.48,17.591 C139.096,15.981 137.237,13.302 137.237,10.049 C137.237,4.133 142.993,0 151.235,0 C157.18,0 163.069,2.001 168.27,5.787 L168.571,6.006 L165.589,10.274 L165.271,10.02 C160.989,6.589 156.287,4.849 151.299,4.849 C146.313,4.849 142.554,7 142.554,9.853 C142.554,10.968 143.047,11.864 144.104,12.666 C146.159,14.224 149.288,14.413 153.249,14.653 C154.796,14.749 156.547,14.855 158.38,15.059 C165.631,15.888 170.315,20.108 170.315,25.809 C170.315,31.399 165.345,37.418 154.43,37.418" id="Fill-7" fill={color}></path>
        <polyline fill={color} points="193.323 36.528 188.213 36.528 188.213 5.553 173.467 5.553 173.467 0.769 208.071 0.769 208.071 5.553 193.323 5.553 193.323 36.528"></polyline>
        <path d="M230.754,37.418 C220.21,37.418 215.908,33.81 215.908,24.964 L215.908,0.769 L221.042,0.769 L221.042,23.798 C221.042,30.848 222.944,32.565 230.754,32.565 C238.569,32.565 240.476,30.848 240.476,23.798 L240.476,0.769 L245.605,0.769 L245.605,24.964 C245.605,33.81 241.303,37.418 230.754,37.418" id="Fill-9" fill={color}></path>
        <path d="M288.81,18.744 C288.81,28.802 282.115,36.143 270.418,36.143 L255.411,36.143 L255.411,1.148 L270.418,1.148 C281.79,1.148 288.81,8.614 288.81,18.744 M259.761,5.174 L259.761,32.118 L270.418,32.118 C279.451,32.118 284.329,26.535 284.329,18.678 C284.329,10.822 279.126,5.174 270.418,5.174 L259.761,5.174" fill={color}></path>
        <path d="M260.144,31.738 L270.418,31.738 C278.768,31.738 283.95,26.736 283.95,18.678 C283.95,10.706 278.641,5.553 270.418,5.553 L260.144,5.553 L260.144,31.738 Z M270.418,36.523 L255.031,36.523 L255.031,0.769 L270.418,0.769 C281.645,0.769 289.19,7.991 289.19,18.744 C289.19,29.544 281.82,36.523 270.418,36.523 L270.418,36.523 Z" id="Fill-12" fill={color}></path>
        <polygon fill={color} points="298.178 36.655 303.885 36.655 303.885 0.578 298.178 0.578"></polygon>
        <path d="M376.174,37.418 C369.148,37.418 363.077,35.372 357.625,31.171 L357.343,30.954 L360.321,26.567 L360.639,26.81 C365.482,30.567 370.976,32.633 376.111,32.633 C382.337,32.633 386.681,29.778 386.681,25.685 C386.681,22.432 383.741,20.246 378.813,19.835 C377.902,19.767 377.016,19.706 376.159,19.647 C371.284,19.313 367.432,19.051 364.221,17.594 C360.84,15.978 358.981,13.302 358.981,10.049 C358.981,4.133 364.74,0 372.979,0 C378.924,0 384.816,2.001 390.019,5.787 L390.321,6.006 L387.333,10.274 L387.015,10.02 C382.734,6.589 378.034,4.849 373.048,4.849 C368.062,4.849 364.3,7 364.3,9.853 C364.3,10.968 364.794,11.861 365.847,12.666 C367.903,14.224 371.035,14.413 374.993,14.653 C376.54,14.749 378.294,14.855 380.127,15.059 C387.38,15.888 392.064,20.108 392.064,25.809 C392.064,31.399 387.089,37.418 376.174,37.418" id="Fill-15" fill={color}></path>
        <path d="M333.366,4.846 C325.688,4.846 320.12,10.69 320.12,18.741 C320.12,26.752 325.688,32.565 333.366,32.565 C341.043,32.565 346.612,26.752 346.612,18.741 C346.612,10.69 341.043,4.846 333.366,4.846 Z M333.366,37.418 C322.658,37.418 314.88,29.56 314.88,18.741 C314.88,7.882 322.658,0.003 333.366,0.003 C344.111,0.003 351.916,7.882 351.916,18.741 C351.916,29.56 344.111,37.418 333.366,37.418 L333.366,37.418 Z" id="Fill-16" fill={color}></path>
      </g>
    </g>
  </svg>
)

export default Logo
