import React from 'react'

const moon = ({ color }) => {
  return (
    <svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Moon</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="/g/sunmoon" transform="translate(-4.000000, -4.000000)" fill={color}>
          <path d="M13.4585809,4.00116811 C8.18291426,4.33514231 4,8.68735832 4,13.9907836 C4,19.5110752 8.53424623,24 14.1102722,24 C18.9375062,24 22.9847747,20.636695 23.9835854,16.1477698 C24.051876,15.8569812 23.9021303,15.5580393 23.6265032,15.4358434 C23.3500504,15.3136601 23.0258687,15.4016327 22.8514455,15.6459953 C21.5489896,17.419274 19.4459686,18.5782787 17.0591017,18.5782787 C13.0967174,18.5782787 9.89765862,15.4111957 9.89765862,11.4884168 C9.89765862,8.7360907 11.4798531,6.35765054 13.7877967,5.18052494 C14.0519065,5.04449551 14.1843721,4.74391456 14.1053856,4.4596528 C14.0255755,4.17455694 13.7565391,3.98396477 13.4586862,4.00106385 L13.4585809,4.00116811 Z M10.8454967,5.88440226 C9.48050456,7.36689239 8.63387454,9.32840696 8.63387454,11.4885211 C8.63387454,16.0875477 12.4136421,19.8295351 17.0591017,19.8295351 C18.7811761,19.8295351 20.383907,19.3098482 21.7193055,18.428495 C20.1815331,21.0081621 17.3603036,22.7489317 14.1102722,22.7489317 C9.21732153,22.7489317 5.26378366,18.8349109 5.26378366,13.990867 C5.26378366,10.2960063 7.58324871,7.17313063 10.8454967,5.88440226 L10.8454967,5.88440226 Z" id="Fill-1"></path>
        </g>
      </g>
    </svg>
  )
}

export default moon
